import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import store from '../store'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/HomePage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: () => import('@/views/user/privacy-policy'),
		requiresAdminAuth: false,
	},
	{
		path: '/license',
		name: 'LicensePage',
		component: () => import('@/views/user/license'),
		requiresAdminAuth: false,
	},
	{
		path: '/terms',
		name: 'TermsCondition',
		component: () => import('@/views/user/terms-condition'),
		requiresAdminAuth: false,
	},
	{
		path: '/password/reset/:token',
		name: 'UserResetPassword',
		component: () => import('@/views/user/auth/ResetPassword'),
		requiresAdminAuth: false
	},	
	{
		path: '/delete-account',
		name: 'VerifyDeleteAccount',
		component: () => import('@/views/user/VerifyAccountPage'),
		requiresAdminAuth: false,
	},
	{
		path: '/deeplink',
		name: 'VenuePage',
		component: () => import('@/views/user/venue'),
		requiresAdminAuth: false,
	},
	{
		path: '/admin',
		redirect: '/admin/login',
		name: 'Admin',
		meta: { layout: AdminLayout },
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import('@/views/admin/auth/Login'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset',
				name: 'ForgotPassword',
				component: () => import('@/views/admin/auth/ForgotPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset/:token',
				name: 'ResetPassword',
				component: () => import('@/views/admin/auth/ResetPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Bottle Raiders',
					metaTags: [{name: 'description',content: 'The dashboard page of our Bottle Raiders.'},{property: 'og:description',content: 'The dashboard page of our Bottle Raiders.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: '403',
				name: '403',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Bottle Raiders',
					metaTags: [{name: 'description',content: 'The dashboard page of our Bottle Raiders.'},{property: 'og:description',content: 'The dashboard page of our Bottle Raiders.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'profile-update',
				name: 'Profile Update',
				component: () => import('@/views/admin/ProfileUpdate'),
				meta: {
					title: 'Dashboard - profile-update',
					metaTags: [{name: 'description',content: 'The login user profile update page of our Bottle Raiders.'},{property: 'og:description',content: 'The dashboard page of our Bottle Raiders.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'change-password',
				name: 'Change Password',
				component: () => import('@/views/admin/ChangePassword'),
				meta: {
					title: 'Dashboard - Bottle Raiders',
					metaTags: [{name: 'description',content: 'The dashboard page of our Bottle Raiders.'},{property: 'og:description',content: 'The dashboard page of our Bottle Raiders.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: "user",
				name: "User",
				redirect: '/admin/user/list',
				children: [
					{
						path: "list",
						name: "User List",
						component: () => import('@/views/admin/user/List'),
						meta: {
							title: 'User Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The user Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The user Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "User Create",
						component: () => import('@/views/admin/user/Create'),
						meta: {
							title: 'User Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The user Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The user Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "User Edit",
						component: () => import('@/views/admin/user/Edit'),
						meta: {
							title: 'User Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The User Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The User Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "business-user",
				name: "Business User",
				redirect: '/admin/business-user/list',
				children: [
					{
						path: "list",
						name: "Business User List",
						component: () => import('@/views/admin/business-user/List'),
						meta: {
							title: 'Business User Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Business User Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Business User Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Business User Create",
						component: () => import('@/views/admin/business-user/Create'),
						meta: {
							title: 'Business User Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Business User Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Business User Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Business User Edit",
						component: () => import('@/views/admin/business-user/Edit'),
						meta: {
							title: 'Business User Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Business User Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Business User Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "article",
				name: "Article",
				redirect: '/admin/article/list',
				children: [
					{
						path: "list",
						name: "Article List",
						component: () => import('@/views/admin/article/List'),
						meta: {
							title: 'Article Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The article Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The article Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					}
				],
			},
			{
				path: "category",
				name: "Category",
				redirect: '/admin/category/list',
				children: [
					{
						path: "list",
						name: "CategoryList",
						component: () => import('@/views/admin/category/List'),
						meta: {
							title: 'Category Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The category Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The category Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "CategoryCreate",
						component: () => import('@/views/admin/category/Create'),
						meta: {
							title: 'Category Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The category Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The category Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "CategoryEdit",
						component: () => import('@/views/admin/category/Edit'),
						meta: {
							title: 'Category Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Category Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The category Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "bottle-type",
				name: "Bottle Type",
				redirect: '/admin/bottle-type/list',
				children: [
					{
						path: "list",
						name: "Bottle Type List",
						component: () => import('@/views/admin/bottle-type/List'),
						meta: {
							title: 'Bottle Type Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The bottle type Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The bottle type Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Bottle Type Create",
						component: () => import('@/views/admin/bottle-type/Create'),
						meta: {
							title: 'Bottle Type Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The bottle type Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The bottle type Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Bottle Type Edit",
						component: () => import('@/views/admin/bottle-type/Edit'),
						meta: {
							title: 'Bottle Type Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Bottle Type Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The bottle type Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "spirit",
				name: "Spirit",
				redirect: '/admin/spirit/list',
				children: [
					{
						path: "list",
						name: "Spirit List",
						component: () => import('@/views/admin/spirit/List'),
						meta: {
							title: 'Spirit Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The spirit Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The spirit Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Spirit Create",
						component: () => import('@/views/admin/spirit/Create'),
						meta: {
							title: 'Spirit Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The spirit Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The spirit Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Spirit Edit",
						component: () => import('@/views/admin/spirit/Edit'),
						meta: {
							title: 'Spirit Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Spirit Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The spirit Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/view",
						name: "Spirit View",
						component: () => import('@/views/admin/spirit/View'),
						meta: {
							title: 'Spirit Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Spirit Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The spirit Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					}
				],
			},
			{
				path: "user-review",
				name: "User Review",
				redirect: '/admin/user-review/list',
				children: [
					{
						path: "list",
						name: "User Review List",
						component: () => import('@/views/admin/user-review/List'),
						meta: {
							title: 'User Review Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The User Review Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The User Review Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					}
				],
			},
			{
				path: "subscription-content",
				name: "Subscription Content",
				redirect: '/admin/subscription-content/list',
				children: [
					{
						path: "list",
						name: "Subscription Content List",
						component: () => import('@/views/admin/subscription-content/List'),
						meta: {
							title: 'Subscription Content List Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Subscription Content List management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Subscription Content List management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Subscription Content Edit",
						component: () => import('@/views/admin/subscription-content/Edit'),
						meta: {
							title: 'Subscription content Edit Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Subscription content Edit management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Subscription content Edit management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "subscription",
				name: "Subscription",
				redirect: '/admin/subscription/list',
				children: [
					{
						path: "list",
						name: "Subscription List",
						component: () => import('@/views/admin/subscription/List'),
						meta: {
							title: 'Subscription Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The subscription Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The subscription Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Subscription Create",
						component: () => import('@/views/admin/subscription/Create'),
						meta: {
							title: 'Subscription Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The subscription Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The subscription Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Subscription Edit",
						component: () => import('@/views/admin/subscription/Edit'),
						meta: {
							title: 'Subscription Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Subscription Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The subscription Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/view",
						name: "Subscription View",
						component: () => import('@/views/admin/subscription/View'),
						meta: {
							title: 'Subscription Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Subscription Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The subscription Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					}
				],
			},
			{
				// Custom Notification
				path: "custom-notification",
				name: "Custom Notification",
				redirect: '/admin/custom-notification/list',
				children: [
					{
						path: "list",
						name: "Custom Notification List",
						component: () => import('@/views/admin/custom-notification/List'),
						meta: {
							title: 'Custom Notification Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Custom Notification Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Custom Notification Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Custom Notification Create",
						component: () => import('@/views/admin/custom-notification/Create'),
						meta: {
							title: 'Custom Notification Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Custom Notification Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Custom Notification Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Custom Notification Edit",
						component: () => import('@/views/admin/custom-notification/Edit'),
						meta: {
							title: 'Custom Notification Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Custom Notification Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Custom Notification Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				// Scanner Log
				path: "scanner-log",
				name: "Scanner Log",
				redirect: '/admin/scanner-log/list',
				children: [
					{
						path: "list",
						name: "Scanner Log List",
						component: () => import('@/views/admin/scanner-log/List'),
						meta: {
							title: 'Scanner Log Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Scanner Log Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Scanner Log Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				// Sponsered Spirit
				path: "sponsored-spirit",
				name: "Sponsored Spirit",
				redirect: '/admin/sponsored-spirit/list',
				children: [
					{
						path: "list",
						name: "Sponsored Spirit List",
						component: () => import('@/views/admin/sponsored-spirit/List'),
						meta: {
							title: 'Sponsored Spirit Management - Bottle Raiders',
							metaTags: [{name: 'description',content: 'The Sponsored Spirit Management page of our Bottle Raiders.'},{property: 'og:description',content: 'The Sponsored Spirit Management page of our Bottle Raiders.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
		],
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
  })

router.beforeEach((to, from, next) => {
	// Set the title
	document.title = to.meta.title || 'Bottle Raiders' // Default title if not set

	// Set the meta tags
	const metaTags = to.meta.metaTags
	if (metaTags) {
		metaTags.forEach(tag => {
			const { name, content } = tag
			const metaTag = document.createElement('meta')
			metaTag.setAttribute(name, content)
			document.head.appendChild(metaTag)
		});
	}

	// Determine the layout based on the meta property of the route
	const layout = to.meta.layout || 'DefaultLayout'
	// Update the layout component dynamically based on the layout value
	if (layout === 'DefaultLayout' || layout === 'AdminLayout') {
        // Check if the Vue instance is available
        if (router.app) {
            router.app.$options.components.Layout = layout === 'AdminLayout' ? AdminLayout : DefaultLayout;
        } else {
            console.error('Vue instance not available.');
        }
    }

	const isAdminLoggedIn = store.getters.isLoggedIn; // Implement the isLoggedIn getter in your store

	if (to.matched.some(route => route.meta.requiresAdminAuth) && isAdminLoggedIn === false) {
		// If the route requires authentication and the user is not logged in
		next('/admin');
	} else {
		if(to.path == '/admin/login' && isAdminLoggedIn === true){
			next('/admin/dashboard');
		}
	}

	if(isAdminLoggedIn === true && to.name != "Dashboard"){
		next();
	}

	next();
})

export default router
